export const config = {
    api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '/img/charitoshouse.png',
    banner_img_root : '/img/charitoshouse-banner.jpg',
    resid : '',
    key_value : 'charitoshouse',
    secret_value : 'charitoshouse',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3090.5825049981377!2d-104.88616738463985!3d39.22964667952091!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876ca4fc64d5d78b%3A0x78a427ba0ff47993!2sCharrito&#39;s%20House%20Mexican%20Restaurant!5e0!3m2!1sen!2sin!4v1652099031534!5m2!1sen!2sin',
    facebook_link:'#',
    RestaurantName:"Charrito's House"
};
